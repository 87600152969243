
import { format, formatDistanceToNow } from "date-fns";
import { timestampToDate } from "../../utilities";

export default {
  name: "relative-time",
  props: {
    value: [Date, Number, String],
    textOnly: Boolean,
    invert: Boolean,
    dark: Boolean,
  },
  data() {
    return {
      label: "",
      timer: null,
    };
  },
  computed: {
    date() {
      return timestampToDate(this.value);
    },
    tooltip() {
      return format(this.date, this.$t("format.date-time-full"));
    },
  },
  methods: {
    updateLabel() {
      this.label = formatDistanceToNow(this.date, { addSuffix: true });
    },
  },
  watch: {
    value() {
      this.updateLabel();
    },
  },
  created() {
    this.updateLabel();
    this.timer = setInterval(this.updateLabel, 15e3);
  },
  destroyed() {
    clearInterval(this.timer);
  },
};
