
export default {
  name: "dialog-header",
  props: {
    icon: String,
    title: String,
    subtitle: String,
    noCloseBtn: Boolean,
  },
};
