
export default {
  name: "recess",
  props: {
    tag: {
      type: String,
      default: "div",
    },
  },
};
