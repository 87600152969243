
export default {
  name: "tooltip",
  props: {
    square: Boolean,
  },
  computed: {
    offset() {
      return this.$q.platform.has.touch ? [0, 20] : [0, 5];
    },
  },
};
