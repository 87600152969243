export default {
  init: false,
  name: "",
  error: null,
  board: null,
  comments: null,
  config: null,
  history: null,
  historyIndex: 0,
  list: [],
  position: null,
  ptn: null,
  selected: null,
  editingTPS: undefined,
  hlSquares: [],
  hoveredSquare: null,
  evaluation: null,
};
