
import {
  formatError,
  formatSuccess,
  formatWarning,
  formatHint,
} from "../../utilities";

export default {
  name: "message-output",
  props: ["error", "success", "warning", "hint", "content-class"],
  methods: {
    formatError,
    formatSuccess,
    formatWarning,
    formatHint,
  },
};
